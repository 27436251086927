<template>
  <download-excel
    class="btn btn-download"
    :class="{ disabled: relatorioCarregando }"
    :title="title"
    :fetch="getData"
    :name="fileNameNow(area)"
    :before-finish="finishDownload"
    :before-generate="startDownload"
    :data="list"
  >
    <span class="btn-download-loading" v-if="relatorioCarregando"> <span class="spinner-spin"></span>Carregando...</span>
    <img v-if="!relatorioCarregando" src="~@/assets/images/icones/download.svg" alt="Download" />
    <span v-if="!relatorioCarregando">{{title}}</span>
    
  </download-excel>
</template>
<script>
import { nowDateString } from "@/utils/date";

export default {
  props: ["area", "list", "title", "getData"],
  computed: {
    relatorioCarregando() {
      return this.$store.state.interacoes.relatorioLoading;
    }
  },
  methods: {
    // gerarRelatorio,
    fileNameNow(preString) {
      return `${preString}_${nowDateString()}.xls`;
    },
    startDownload() {
      this.$store.commit("interacoes/SET_LOADING", true);
    },
    finishDownload() {
      this.$store.commit("interacoes/SET_LOADING", false);
    },
  },
};
</script>
<style scoped>
.disabled {
  opacity: 0.6;
  pointer-events: none;
}
.btn-download-loading {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: transparent;
}

@keyframes spin {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(359deg);
  }
}

.spinner-spin {
  width: 20px;
  height: 20px;
  margin-right: 10px;
  border: 3px solid #fff;
  border-top: 3px solid transparent;
  border-radius: 50%;
  animation: spin 0.5s linear 0s infinite;
}
</style>
