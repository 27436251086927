export function numberToReal(value) {
  if (value) {
    var n = value.toFixed(2).toString();
    var numero = n.split(".");
    numero[0] = "R$ " + numero[0].split(/(?=(?:...)*$)/).join(".");
    return numero.join(",");
  }
}

export function numberToDollar(value) {
  if (value) {
    var n = value.toFixed(2).toString();
    var numero = n.split(".");
    numero[0] = "$ " + numero[0].split(/(?=(?:...)*$)/).join(",");
    return numero.join(".");
  }
}
